import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import SprintersImg from '../../../images/sprinter.jpg'

const paginaServizio = {
  titolo: 'Sprinters',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: SprintersImg,
      paragrafi: [
        {
          id: 1,
          testo:
            'Grazie alla vasta gamma di soluzioni Sprinter Services puoi personalizzare al massimo le tue spedizioni per rispondere anche alle più specifiche e particolari esigenze di consegna e ritiro.',
        },
        {
          id: 2,
          testo:
            'Servizi time definite, consegne concordate, verifica del contenuto, facchinaggio e consegna tramite mezzi speciali sono solo alcune delle opzioni disponibili.',
        },
        {
          id: 3,
          testo:
            'GLS offre anche servizi speciali pensati per la speciale area della Laguna di Venezia.',
        },
        {
          id: 4,
          testo:
            'Contatta la tua Sede di competenza per scoprire tutta la gamma degli Sprinter Services e Venezia Laguna Services.',
        },
        {
          id: 5,
          testo: 'GLS un mondo di offerte su misura per te!',
        },
      ],
      pesoDimensioni: null,
      vantaggi: null,
      compatibileCon: null,
      servizi: [
        {
          id: 1,
          testo1: '12:00 Service',
          testo2: 'Entro ore 12',
        },
        {
          id: 2,
          testo1: 'Anticipated Service',
          testo2: 'Anticipato',
        },
        {
          id: 3,
          testo1: 'FixedHour Service',
          testo2: 'Ora fissa',
        },
        {
          id: 4,
          testo1: 'PickUp13 Service',
          testo2: 'Ritiro entro ore 13',
        },
        {
          id: 5,
          testo1: 'ContentCheck Service',
          testo2: 'Verifica contenuto',
        },
        {
          id: 6,
          testo1: 'AtFloor Service',
          testo2: 'Consegna al piano',
        },
        {
          id: 7,
          testo1: 'SpecialVehicle Service',
          testo2: 'Mezzo idoneo',
        },
      ],
    },
  ],
}

const Sprinters = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default Sprinters
